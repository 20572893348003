$(function() {
    $('.js-burger').click(function () {
        $(this).toggleClass('burger_active');
        $('.header__nav').toggleClass('header__nav_active');
        $('body').toggleClass('o-hidden');
    });

    $("a.nav__link").click(function() {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });
})
