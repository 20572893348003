$(function() {
    let element = $(".banner"), display;
    $(window).scroll(function () {
        display = $(this).scrollTop() >= 250;
        if(display){
            element.show();
        }else{
            element.hide();
        }
    });
})
